var Translate = {
    en:{
        pageTitle:"We don't want to say goodbye!",
        formTitle:"Are you sure you want to cancel your account?",
        formSubtitle:"Help us to improve!",
        formText:"Your opinion is important to us.",
        formSubText:"Write us your experience and why you decided to cancel your account at Wirebit.",
        formOp1:"I didn’t use my account",
        formOp2:"I don’t have enough benefits",
        formOp3:"I don’t have enough experience",
        formOp4:"Another reason",
        formOp5:"Dissatisfied with the service",
        fromLeave:"Leave us your comments",
        formBtn:"Send",
        formTitleSts1:"Request sent",
        formSubtitleSts1:"Your account cancellation is being processed, We will contact you shortly.",
        incompleteVlaues:"Please select the reason for your departure",
        sucAlert:"Your account deletion process is being attended to, we will answer you shortly.",
        error:"Something went wrong, please try again",

        //Sección Email
        subject:"Deletion of account in process",
        text1:"Delete account",
        text2:"Dear customer:",
        text3:"Your process of ",
        text4:"account deletion",
        text5:"is being attended to, we will answer you shortly.",
        text6:"If you don't recognize this operation you can contact ",
        text7:"Connectivity with the market at any time",
    },
    es:{
        pageTitle:'¡No queremos despedirnos de ti!',
        formTitle:'¿Estás seguro de cancelar tu cuenta?',
        formSubtitle:'¡Ayúdanos a mejorar!',
        formText:'Tu opinión es importante para nosotros.',
        formSubText:'Escríbenos tu experiencia y por qué decides cancelar tu cuenta en Wirebit.',
        formOp1:'No usaba mi cuenta',
        formOp2:'No cuento con suficientes beneficios',
        formOp3:'No cuento con suficiente experiencia',
        formOp4:'Otra razón',
        formOp5:'Insatisfecho con el servicio',
        fromLeave:'Déjanos tus comentarios',
        formBtn:'Enviar',
        formTitleSts1:'Solicitud enviada',
        formSubtitleSts1:'La cancelación de tu cuenta está siendo procesada, en breve te contactaremos.',
        incompleteVlaues:'Porfavor selecciona el motivo de tu partida',
        sucAlert:'Tu proceso de eliminación de cuenta está siendo atendida, en breve te contestaremos.',
        error:'Algo salió mal, porfavor intentalo nuevamente',

        //Sección Email
        subject:'Eliminación de cuenta en proceso',
        text1:'Eliminación de cuenta',
        text2:'Estimado cliente:',
        text3:'Tu proceso de ',
        text4:'eliminación de cuenta',
        text5:'está siendo atendido, en breve te contestaremos.',
        text6:'Si no reconoce esta operación puede comunicarse a ',
        text7:'Conectividad con el mercado en cualquier momento',
    }
}
export default Translate;