import React, { Component } from 'react';
import {Col,Row,Container,Button,Alert, Form, Image } from 'react-bootstrap';
import { BsCheckLg } from "react-icons/bs";
import "react-activity/dist/library.css";
import Check from '../img/check.svg'
import { Dots } from 'react-activity';
import Translate from './Translate';
import Logo from '../img/logo.png';
import Backend from '../backend';
import './delete.css';

const valores = ['formOp1','formOp2','formOp3','formOp5','formOp4']

export default class DeleteForm extends Component {

    constructor(props){
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        const name = urlParams.get('name');
        const token = urlParams.get('secret');
        const lan = urlParams.get('lan').split('_US')[0];
        this.state = {
            spinner: false,
            name,
            email,
            token,
            lan,
            reason:'',
            comentarios:'',
            pageStatus:0,
            alertInfo:{tipo:'',mensaje:''}
        }
    }

    renderOptions(){
        return valores.map((item, i )=>{
            return(
                <Form.Group className='myFormGroups' controlId={item} >
                    <Row className='formBasicCheckbox'>
                        <Button className={'validate-button check '+(this.state.reason === item && 'selected')} onClick={()=>this.setState({reason:item})}><BsCheckLg className='myCheckIcon' /></Button>
                        <Form.Label className='myRadioStyle'>{Translate[this.state.lan][item]}</Form.Label>
                    </Row>
                </Form.Group>
            )
        })
    }

    sendData(){
        const self = this;
        self.setState({spinner:true})
        var message = self.state.comentarios.replace(/\n/g, '<br></br>')
    
        if(self.state.reason.trim() !== ''){
            Backend.deleteAccount(self.state.email, Translate['es'][self.state.reason],message,self.state.token,response =>{
                if(response.status.code === '0'){
                    self.setState({pageStatus:1,spinner: false})
                }
                else {
                    self.setState({spinner: false, showAlert:true,alertInfo:{tipo:'danger',mensaje:Translate[self.state.lan].error}})
                    setTimeout(function(){
                        self.setState({showAlert:false,alertInfo:{tipo:'',mensaje:''}})
                    }, 4000);
                }
            })            
        }
        else {
            self.setState({spinner: false, showAlert:true,alertInfo:{tipo:'danger',mensaje:Translate[self.state.lan].incompleteVlaues}})
            setTimeout(function(){
                self.setState({showAlert:false,alertInfo:{tipo:'',mensaje:''}})
            }, 4000);
        }
    }

    renderAlert(){
        return(
            <Alert style={{marginTop:15}} variant={this.state.alertInfo.tipo}>
                <Alert.Heading style={{textAlign:'center'}} >{this.state.alertInfo.mensaje}</Alert.Heading>
            </Alert>
        )   
    }

    render() {
        return (
            <div className={'backgroundSI'}>
                <Container>
                    <Row>
                        <Col md={12}className="centerDiv">
                        <Image src={Logo} className={"cancel-logo-style"} />
                        </Col>
                    </Row>
                    {this.state.pageStatus === 0
                    ?
                    <>
                        <Row style={{marginTop:20}} >
                            <Col md={12} className='myCol'>
                                <p className='titleCancel' >{this.state.name}{this.state.name.trim() !== '' && ','} {Translate[this.state.lan].pageTitle} </p>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col md={12} className='myCol' >
                                <div className={"cancelFormDiv"}>
                                    <div className="titlesDiv">
                                      <p className={"cancelFormTitle"} >{Translate[this.state.lan].formTitle}</p>
                                      <p className={"cancelFormSubTitle"} >{Translate[this.state.lan].formSubtitle}</p>
                                    </div>
                                    <div className="subtitlesDiv" >
                                      <p className={"cancelFormText"} >{Translate[this.state.lan].formText}</p>
                                      <p className={"cancelFormSubText"} >{Translate[this.state.lan].formSubText}</p>
                                    </div>
                                    <Form className='myFormStyle' >
                                      <Row className='spaceForFormRow'>
                                        {this.renderOptions()}
                                      </Row>
                                      <Row className='spaceForFormRow'>
                                        <Form.Group className="myTextAreaForm" controlId="explicacion">
                                          <Form.Label className='myTextAreaTitle' >{Translate[this.state.lan].fromLeave}</Form.Label>
                                          <Form.Control as="textarea" className="myTextarea" onChange={(e)=>this.setState({comentarios:e.target.value})}  />
                                        </Form.Group>
                                      </Row>
                                    </Form>

                                    <Row style={{display:'flex', justifyContent:'center', marginRight:0, marginLeft:0}}>
                                        <Button className="sendCancelBtn" onClick={()=>{this.sendData()}} disabled={this.state.spinner} >
                                        {
                                        (this.state.spinner)
                                        ?<Dots color="#fff" size={20} speed={1} animating={this.state.spinner}/>
                                        :Translate[this.state.lan].formBtn
                                        }</Button>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Row style={{marginTop:20}}>
                            <Col md={12} className='myCol' >
                            <div className={"cancelFormDiv cancelFormStatus2"}>
                                <div className="titlesDiv centerDiv">
                                <Row style={{width:'100%', display:'flex', justifyContent:'center', marginRight:0, marginLeft:0}} ><Image src={Check} className={"finalCheck"} /></Row>
                                <p className={"cancelFormTitle finalTitleText"} >{Translate[this.state.lan].formTitleSts1}</p>
                                <Row style={{width:'100%', display:'flex', justifyContent:'center', marginRight:0, marginLeft:0}} ><p className={"cancelFormSubTitle finalSubTitleText"} >{Translate[this.state.lan].formSubtitleSts1}</p></Row>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    </>
                    }
                    <Row style={{width:'100%', display:'flex', justifyContent:'center', marginBottom:15}} >
                        { (this.state.showAlert == true)&&
                            this.renderAlert()
                        }
                    </Row>
                </Container>
            </div>
        );
    }
}
