var url_webapi = 'https://webapi.wirebit.com';

module.exports = {
    deleteAccount: function deleteAccount(email,motivo,comentario,auth,callback){      
        fetch(url_webapi + '/webapiv0', {
            "method": "POST",
            "headers": {
              'Content-Type': 'application/json',
            },
            "body":JSON.stringify({
              "payload": {
                "w01": "g_login",
                "w02": email,
                "w03": auth,
                "w04": {
                    "function": "account_delete_request",
                    "reason": motivo,
                    "comment": comentario
                },
                "w05": "_"
              }
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
          callback(responseJson)
        })
        .catch((error) => {
          console.log(error);
        })
    },
}
